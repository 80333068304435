import React from "react";
import {
    FindPrecioEspecial,
    FindPrecioFamiliar,
    FindPrecioMenor,
    GetPrecioCosto,
    getPrecioPorMayor
} from "../Preventas/PreciosPreventa";
import {calculateCotizaciones, decimalAdjust} from "../../Global";
import {extractTwoDecimalsForNumber} from "../../helpers/calcularVenta";
import {notificarError, notificarMsg} from "../../componentes/Almacenes/AlmacenNotify";

export default class DetalleCotizacion extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            cotizacion: {},
            detalles: [],
            Gravado: 0,
            Inafecto: 0,
            Exonerado: 0,
            IGVPreventa: 0,
            Gratuitas: 0,
            totalMonto: 0,
            ICBPERPreventa: 0,
            redondeo: 0,
            canSaveChanges: true
        }
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        if (id)
            this.fetchCotizacion(id);
        else
            this.props.history.goBack()
    }

    async fetchCotizacion(id) {
        this.setState({isLoading: true})
        let res = await fetch(`/api/cotizaciones/detalles/${id}`)
        let data = await res.json();
        let detsProd = data.dets.map(dp => ({
            ...dp,
            PrecioVenta: dp.precioVenta,
            IdTipoStock: dp.IdTipoStock,
            Tributos: dp.Tributos,
            TasaISC: dp.TasaISC,
            PrecioEspecial: FindPrecioEspecial([], dp),
            PrecioFamiliar: FindPrecioFamiliar([], dp),
            PrecioCosto: GetPrecioCosto([], dp),
            PrecioMenor: FindPrecioMenor([], dp),
            oldPrecios: [],
            precioMayor: getPrecioPorMayor([], dp),
        }))
        this.montoTotalDetalle(detsProd)
        this.setState({isLoading: false, detalles: detsProd, cotizacion: data.cot})
    }


    montoTotalDetalle(items) {
        const itemsTmp = [...items];
        const totales = calculateCotizaciones(itemsTmp);
        this.setState({
            Gravado: extractTwoDecimalsForNumber(decimalAdjust('floor', totales.gravados, -3)),
            Inafecto: extractTwoDecimalsForNumber(totales.inafectos),
            Exonerado: extractTwoDecimalsForNumber(totales.exonerados),
            IGVPreventa: extractTwoDecimalsForNumber(totales.gravados * 0.10),
            Gratuitas: extractTwoDecimalsForNumber(totales.gratuitos),
            totalMonto: totales.totalRedondeado,
            ICBPERPreventa: extractTwoDecimalsForNumber(totales.icbper),
            redondeo: totales.redondeo,
            descuento: totales.descuento
        })
    }

    async guardarCambios() {
        try {
            const tasks = this.state.detalles.map(async (item) => {
                await fetch(`/api/cotizaciones/detalles/${item.id}`, {
                    method: "PUT",
                    body: JSON.stringify({
                        idCotizacion: item.idCotizacion,
                        cantidad: item.cantidad,
                        total: item.total,
                        descuento: item.Descuento? item.Descuento : 0,
                        precioVenta: item.precioVenta,
                        valorUnitario: item.valorUnitario,
                        idAfectacionIgv: item.idAfectacionIgv,
                        entregado: item.entregado
                    }),
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                })
            })

            await Promise.all(tasks)

            notificarMsg("Cotización actualizada con éxito.");

            const id = this.props.match.params.id;
            if (id)
                this.fetchCotizacion(id);
        } catch (e) {
            notificarError("Ocurrió un error! Intente otra vez.")
        }
    }

    onChangeQuantity(index, value) {
        const detalle = this.state.detalles[index];
        const input = document.getElementById(`det-cot-entregado-${index}`)

        input.classList.remove('is-invalid')

        if (isNaN(Number(value)) || Number(value) > detalle.cantidad || Number(value) < 0) {
            input.classList.add('is-invalid')
            this.setState({ canSaveChanges: false })
            return;
        }

        this.setState({ canSaveChanges: true })

        this.state.detalles[index].entregado = Number(value);
    }

    render() {
        const {cotizacion, detalles} = this.state;

        if (this.state.isLoading)
            return (
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                    </div>
                </div>
            )

        return (
            <div className="container">
                <div className="row mt-3">
                    <div className="col-sm-auto">
                        <a href="#" onClick={e => {
                            e.preventDefault();
                            this.props.history.goBack()
                        }}>
                            Regresar
                        </a>
                    </div>
                    <div className="col-sm-auto">
                        Cliente: {cotizacion.razonSocial}
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-sm-12">
                        <table className="table table-hover">
                            <thead>
                            <tr>
                                <th>Producto</th>
                                <th>Precio de venta</th>
                                <th>Cantidad</th>
                                <th>Descuento</th>
                                <th>Total</th>
                                <th>Entregado</th>
                                <th>Por entregar</th>
                            </tr>
                            </thead>
                            <tbody>
                            {detalles.map((d, index) => (
                                <tr key={`det-cot-${d.id}`}>
                                    <td>{d.descripcion} {d.unidadMedida}</td>
                                    <td>{extractTwoDecimalsForNumber(d.precioVenta)}</td>
                                    <td>{d.cantidad}</td>
                                    <td>{d.Descuento}</td>
                                    <td>{extractTwoDecimalsForNumber(d.total)}</td>
                                    <td>
                                        <input id={`det-cot-entregado-${index}`} type="text" className="form-control form-control-sm" defaultValue={d.entregado} onChange={(e) => this.onChangeQuantity(index, e.target.value)}/>
                                    </td>
                                    <td>
                                        {d.cantidad - Number(d.entregado || 0)}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row mt-3">

                    {parseFloat(this.state.Gravado) > 0 ?
                        <div className="col-sm-auto">
                            <p>
                                Gravado{" "}
                                <strong>
                                    {this.state.Gravado}
                                </strong>
                            </p>
                        </div>
                        : null
                    }

                    {parseFloat(this.state.Inafecto) > 0 ?
                        <div className="col-sm-auto">
                            <p>
                                Inafecto{" "}
                                <strong>
                                    {this.state.Inafecto}
                                </strong>
                            </p>
                        </div>
                        : null
                    }

                    {parseFloat(this.state.Exonerado) > 0 ?
                        <div className="col-sm-auto">
                            <p>
                                Exonerado{" "}
                                <strong>
                                    {this.state.Exonerado}
                                </strong>
                            </p>
                        </div>
                        : null
                    }

                    {parseFloat(this.state.Gratuitas) > 0 ?
                        <div className="col-sm-auto">
                            <p>
                                Gratuitas{" "}
                                <strong>
                                    {this.state.Gratuitas}
                                </strong>
                            </p>
                        </div>
                        : null
                    }

                    {parseFloat(this.state.ICBPERPreventa) > 0 ?
                        <div className="col-sm-auto">
                            <p>
                                ICBPER{" "}
                                <strong>
                                    {this.state.ICBPERPreventa}
                                </strong>
                            </p>
                        </div>
                        : null
                    }

                    {parseFloat(this.state.IGVPreventa) > 0 ?
                        <div className="col-sm-auto">
                            <p>
                                IGV{" "}
                                <strong>
                                    {this.state.IGVPreventa}
                                </strong>
                            </p>
                        </div>
                        : null
                    }

                    {parseFloat(this.state.redondeo) > 0 ?
                        <div className="col-sm-auto">
                            <p>
                                Descuento <strong>
                                {decimalAdjust('floor', this.state.descuento, -2)}
                            </strong>
                            </p>
                        </div>
                        : null
                    }

                    {parseFloat(this.state.redondeo) > 0 ?
                        <div className="col-sm-auto">
                            <p>
                                Redondeo <strong>
                                {decimalAdjust('floor', this.state.redondeo, -2)}
                            </strong>
                            </p>
                        </div>
                        : null
                    }
                    {parseFloat(this.state.totalMonto) > 0 ?
                        <div className="col-sm-auto">
                            <p>
                                Total <strong>
                                {this.state.totalMonto}
                            </strong>
                            </p>
                        </div>
                        : null
                    }
                </div>
                <div className="row">

                    <div className="col-12 col-md-3 col-lg-2 ml-auto">
                        <button type="button" className="btn btn-success btn-verde-mfp w-100" title="Guardar cambios" onClick={() => this.guardarCambios()} disabled={!this.state.canSaveChanges}>Guardar</button>
                    </div>
                </div>
            </div>
        );
    }
}
