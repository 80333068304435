import React from "react";
import moment from "moment";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import ReactTable from "react-table";
import matchSorter from "match-sorter";
import {calculateCotizaciones, decimalAdjust} from "../../Global";
import printA4, {crearDocPDF} from "../../helpers/A4";
import {
    FindPrecioEspecial,
    FindPrecioFamiliar,
    FindPrecioMenor,
    GetPrecioCosto, getPrecioPorMayor
} from "../../componentes/Preventas/PreciosPreventa";
import printTicket from "./Ticket";
import Modal from "../../componentes/Modal";
import {notificarError, notificarMsg} from "../../componentes/Almacenes/AlmacenNotify";
import { CURRENT_QUOTATION_KEY } from './utils';

export default class Cotizaciones extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fechaInicio: moment(new Date()).format('YYYY-MM-DD'),
            fechaFin: moment(new Date()).format('YYYY-MM-DD'),
            cliente: {},
            isLoading: false,
            cotizaciones: [],
            extImg: 'png',
            showEmailModal: false,
            selectedCot: {},
            email: "",
            cuentasBancarias: [],
        }

        this.onChangeCliente = this.onChangeCliente.bind(this);
        this.onChangeFecha = this.onChangeFecha.bind(this);
        this.fetchCotizaciones = this.fetchCotizaciones.bind(this);
        this.onPrintPdf = this.onPrintPdf.bind(this);
        this.onPrintTicket = this.onPrintTicket.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
        this.emitirVenta = this.emitirVenta.bind(this);
        this.fetchCuentasBancarias = this.fetchCuentasBancarias.bind(this);
        this.fetchNumerosCuenta = this.fetchNumerosCuenta.bind(this);
        this.changeEstado = this.changeEstado.bind(this);
    }

    componentDidMount() {
        // this.fetchCuentasBancarias();

        sessionStorage.removeItem(CURRENT_QUOTATION_KEY);
        this.fetchCotizaciones();
        this.fetchNumerosCuenta();
    }

    async fetchCuentasBancarias() {
        this.setState({isLoading: true});
        let res = await fetch('/api/cuentas/');
        if (res.ok)
            this.setState({cuentasBancarias: await res.json()})
        this.setState({isLoading: false});
    }

    async fetchNumerosCuenta() {
        this.setState({loading: true});
        let res = await fetch('/api/cuentas/numeroscuenta');
        const cuentas = await res.json();
        // console.log({cuentas: cuentas})
        if (res.ok)
            this.setState({cuentasBancarias: cuentas})
        this.setState({loading: false});
    }

    searchClients(query) {
        return new Promise((resolve, reject) => {
            fetch(`/api/clientes/buscar/?query=${query}`)
                .then(r => r.json())
                .then(clientes => {
                    const mappedClientes = clientes.map(c => {
                        return {
                            label: `${c.PrimerNombre} ${c.SegundoNombre || ""} - ${c.RazonSocial || ""} ${c.NroTipoDocumento || ""}`,
                            value: c.IdCliente
                        }
                    });
                    resolve(mappedClientes)
                }).catch(reject)
        })
    }

    onChangeCliente(test) {
        this.setState({
            cliente: test
        })
    }

    onChangeFecha(e) {
        const elem = e.target;
        this.setState((state, props) => {
            let newState = {...state};
            newState[elem.getAttribute('name')] = elem.value
            return newState;
        })
    }

    async fetchCotizaciones() {
        this.setState({isLoading: true})
        const idCliente = this.state.cliente && this.state.cliente.value ? this.state.cliente.value : "";
        const query = `fechaInicio=${this.state.fechaInicio}&fechaFin=${this.state.fechaFin}&idCliente=${idCliente}&estado=GENERADO`;
        let resCotizaciones = await fetch(`/api/cotizaciones/?${query}`);
        let data = await resCotizaciones.json();
        this.setState({isLoading: false, cotizaciones: data.cots, extImg: data.extImg})
    }

    onDetalleCotizacion(cotizacion) {
        window.localStorage.removeItem('cotizacionId')
        this.props.history.push(`/cotizaciones/detalle/${cotizacion.id}`)
    }

    onDuplicarCotizacion(cotizacion){
        window.localStorage.removeItem('cotizacionId')
        this.props.history.push(`/cotizaciones/crear/${cotizacion.id}`)
    }

    async onPrintPdf(cotizacion) {
        this.setState({isLoading: true})
        const [venta, detsProd] = await this.fetchAndMappData(cotizacion);
        await printA4(detsProd, {...venta, EsCotizacion: true }, this.state.extImg, this.state.cuentasBancarias);
        this.setState({isLoading: false})
    }

    async onPrintTicket(cotizacion) {
        this.setState({isLoading: true})
        const [venta, detsProd] = await this.fetchAndMappData(cotizacion);
        await printTicket(detsProd, venta, this.state.extImg);
        this.setState({isLoading: false})
    }

    openEmailModal(cotizacion) {
        this.setState({showEmailModal: true, selectedCot: cotizacion, email: cotizacion.CorreoElectronico})
    }

    emitirVenta(cotizacion){
        sessionStorage.setItem(CURRENT_QUOTATION_KEY, cotizacion.id)
        this.props.history.push(`/pre-invoice/add/${"c-" + cotizacion.id}`)
    }

    async fetchAndMappData(cotizacion) {
        let res = await fetch(`/api/cotizaciones/detalles/${cotizacion.id}`)
        let data = await res.json();
        let detsProd = data.dets.map((dp, i) => ({
            ...dp,
            PrecioVenta: dp.precioVenta,
            IdTipoStock: dp.IdTipoStock,
            Tributos: dp.Tributos,
            TasaISC: dp.TasaISC,
            PrecioEspecial: FindPrecioEspecial([], dp),
            PrecioFamiliar: FindPrecioFamiliar([], dp),
            PrecioCosto: GetPrecioCosto([], dp),
            PrecioMenor: FindPrecioMenor([], dp),
            oldPrecios: [],
            precioMayor: getPrecioPorMayor([], dp),
            Cantidad: dp.cantidad,
            Total: dp.total,
            Importe: dp.total,
            Unidad: dp.unidadMedida,
            Descuento: dp.Descuento,
            Indice: i + 1,
        }))

        const totales = calculateCotizaciones(detsProd);
        //console.log({totales,detsProd,cotizacion,data})
        let venta = {
            ...cotizacion,
            FechaEmision: cotizacion.fechaEmision,
            RazonSocial: cotizacion.razonSocial,
            Descripcion: "Cotización",
            Observacion: cotizacion.descripcion,
            Serie: cotizacion.serie,
            NumeroComprobante: cotizacion.id,
            Redondeo: 0,
            Gravadas: totales.gravados,
            Exoneradas: totales.exonerados,
            Inafectas: totales.inafectos,
            ICBPER: totales.icbper,
            IGV: decimalAdjust('floor', totales.gravados * 0.10, -2),
            Exportacion: 0,
            Total: totales.total,
            Direccion: cotizacion.Direccion || "",
            Gratuitas: 0,
            ISC: 0,
            IVAP: 0,
            DescuentoTotal: 0,
            TotalRedondeo: totales.total,
            infoCotizaciones: ["Esta cotización es válida hasta agotar stock.",
                "Los precios pueden cambiar sin previo aviso."]
        }

        return [venta, detsProd];
    }

    onCloseModal() {
        this.setState({showEmailModal: false})
    }

    onKeyUpModal(e) {
        if (e.key === "Escape")
            this.setState({showEmailModal: false})
    }

    async sendEmail(e) {
        e.preventDefault();
        if (this.state.email && this.state.email.length) {
            this.setState({isLoading: true})
            const [venta, detsProd] = await this.fetchAndMappData(this.state.selectedCot);
            let data = await this.getDataForSend(detsProd, venta);
            let res = await fetch('/api/cotizaciones/send', {
                method: 'post',
                headers: {
                    "Accept": "application/json",
                },
                body: data
            })
            if (res.ok)
                notificarMsg('Se está enviando el correo.', 'success')
            else
                notificarError('Ha ocurrido un error al momento de enviar el correo.')
            this.setState({isLoading: false, showEmailModal: false})
        } else
            notificarError('Debe ingresar un email.')
    }

    async getDataForSend(detsProd, venta) {
        let docpdf = await crearDocPDF(detsProd, {...venta, EsCotizacion: true }, this.state.extImg);
        let blob = await docpdf.output('blob');
        let data = new FormData();
        data.append('email', this.state.email);
        data.append('idCotizacion', this.state.selectedCot.id)
        data.append('pdf', blob, 'venta_pdf.pdf')
        return data;
    }

    async changeEstado(cotizacion, estado) {
        try {
            const req = await fetch(`/api/cotizaciones/update/${cotizacion.id}`, {
                method: 'PUT',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    estado
                })
            })

            if (!req.ok) {
                throw new Error("Ocurrió un error")
            };

            notificarMsg("Cotización actualizada con éxito.");

            this.fetchCotizaciones();
        } catch (e) {
            notificarError("Ocurrió un error! Intente otra vez.")
        }
    }

    render() {
        return (
            <section className="ventas-fpay-section pt-5 pb-5">

                <div className="justify-content-center contenedor-fecha-fpay caja-fondo-blanco-m-2">
                    <span className="align-self-center letra-fecha-fpay">
                        Desde:
                    </span>
                    <div className="col-sm">
                        <input onChange={this.onChangeFecha} type="date" disabled={this.state.isLoading}
                            name="fechaInicio" className="input__linea mr-1 pl-2 mt-3 mb-3 linea-fecha-fpay"
                            value={this.state.fechaInicio} />
                    </div>
                    <span className="align-self-center letra-fecha-fpay">
                        Hasta:
                    </span>
                    <div className="col-sm">
                        <input onChange={this.onChangeFecha} type="date" disabled={this.state.isLoading}
                            name="fechaFin" className="input__linea mr-1 pl-2 mt-3 mb-3 linea-fecha-fpay"
                            value={this.state.fechaFin} />
                    </div>
                </div>

                <div className="container">
                    <div className="caja-fondo-blanco-m-2">
                        <h5 className="sucursal-text-color pad-15-fpay">
                            Cliente
                        </h5>
                        <div className="">
                            <AsyncSelect
                                defaultOptions={true}
                                onChange={this.onChangeCliente}
                                value={this.state.cliente}
                                loadOptions={this.searchClients}
                                isClearable={true}
                                isLoading={this.state.isLoading}
                            />
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="seccion-btn-verde-mfp mt-3">
                        <div className="seccion-btn-verde-mfp mr-3">
                            <button onClick={this.fetchCotizaciones} disabled={this.state.isLoading}
                                className="btn btn-outline-primary btn-verde-mfp">
                                Filtrar
                            </button>
                        </div>
                        <div className="seccion-btn-verde-mfp">
                            <button className="btn btn-outline-primary btn-verde-mfp" href="#" onClick={e => {
                                e.preventDefault();
                                window.localStorage.removeItem('cotizacionId');
                                this.props.history.push('/cotizaciones/crear')
                            }}>
                                Crear cotización
                            </button>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row mt-3">
                        <div className="col-sm-12">
                            <ReactTable
                                data={this.state.cotizaciones}
                                filterable
                                columns={[
                                    {
                                        columns: [
                                            {
                                                Header: "Fecha emisión",
                                                id: "fechaEmision",
                                                width: "180",
                                                filterable: true,
                                                accessor: d => moment(d.fechaEmision).format('DD-MM-YYYY HH:mm'),
                                                className: "centrado-cot"
                                            },
                                            {
                                                Header: "Serie-Correlativo",
                                                id: "serieNum",
                                                width: "180",
                                                accessor: d => d.serieNum,
                                                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ["serieNum"]}),
                                                filterable: true,
                                                className: "centrado-cot"
                                            },
                                            {
                                                Header: "Cliente",
                                                id: "razonSocial",
                                                width: "180",
                                                accessor: d => d.razonSocial,
                                                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ["razonSocial"]}),
                                                filterable: true,
                                                className: "centrado-cot"
                                            },
                                            {
                                                Header: "Importe total",
                                                id: "total",
                                                width: "150",
                                                filterable: true,
                                                Cell: (d) => decimalAdjust('floor', d.original.total, -2),
                                                className: "centrado-cot"
                                            },
                                            {
                                                Header: "Estado",
                                                id: "estado",
                                                width: "150",
                                                filterable: true,
                                                accessor: d => d.estado,
                                                className: "centrado-cot"
                                            },
                                            {
                                                Header: "Acciones",
                                                width: "500",
                                                className: 'ancho-prueba',
                                                filterable: false,
                                                Cell: (d) => {
                                                    return <>
                                                        <div className="dropdown drop-resp">
                                                            <button className="btn options-ventas" type="button"
                                                                    data-toggle="dropdown" aria-expanded="false">

                                                            </button>
                                                            <div className="dropdown-menu">
                                                                {
                                                                    (['GENERADO'].includes(d.original.estado) || !d.original.estado) && <>
                                                                        <div className="dropdown-item">
                                                                            <button className="btn btn-success btn-sm w-100"
                                                                                    disabled={this.state.isLoading}
                                                                                    title={"Aprobar"}
                                                                                    onClick={() => this.changeEstado(d.original, "APROBADO")}
                                                                            >
                                                                                <i className="fas fa-check"></i> Aprobar 
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                }
                                                                {
                                                                    (['GENERADO'].includes(d.original.estado) || !d.original.estado) && <>
                                                                        <div className="dropdown-item">
                                                                            <button className="btn btn-danger btn-sm w-100"
                                                                                    disabled={this.state.isLoading}
                                                                                    title={"Rechazar"}
                                                                                    onClick={() => this.changeEstado(d.original, "RECHAZADO")}
                                                                            >
                                                                                <i className="fas fa-times"></i> Rechazar 
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                }
                                                                <div class="dropdown-divider"></div>
                                                                <div className="dropdown-item">
                                                                    <button onClick={() => this.onDetalleCotizacion(d.original)}
                                                                            className="btn btn-secondary btn-sm w-100"
                                                                            style={{backgroundColor: "#FB8D00",color:"#FFFFFF",borderColor:"#FB8D00"}}
                                                                            disabled={this.state.isLoading}
                                                                            title={"Detalle"}
                                                                    >
                                                                        <i className="fas fa-info-circle"></i> Detalle
                                                                    </button>
                                                                </div>
                                                                <div className="dropdown-item">
                                                                    <button onClick={() => this.onDuplicarCotizacion(d.original)}
                                                                        className="btn btn-primary btn-sm w-100"
                                                                        disabled={this.state.isLoading}
                                                                        title={"Duplicar"}
                                                                    >
                                                                        <i className="fas fa-clone"></i> Duplicar
                                                                    </button>
                                                                </div>
                                                                <div className="dropdown-item">
                                                                    <button onClick={() => this.onPrintPdf(d.original)}
                                                                        className="btn btn-danger btn-sm w-100"
                                                                        disabled={this.state.isLoading}
                                                                        title={"PDF"}
                                                                    >
                                                                    <i className="far fa-file-pdf"></i> PDF
                                                                    </button>
                                                                </div>
                                                                <div className="dropdown-item">
                                                                    <button onClick={() => this.openEmailModal(d.original)}
                                                                        className="btn btn-warning btn-sm w-100"
                                                                        disabled={this.state.isLoading}
                                                                        title={"Enviar un correo"}
                                                                        style={{backgroundColor: "#ffdf00",color:"#FFFFFF",borderColor:"#ffdf00"}}
                                                                    >
                                                                        <i className="fas fa-envelope-open-text"></i> Enviar Correo
                                                                    </button>
                                                                </div>
                                                                {
                                                                    ['APROBADO'].includes(d.original.estado) && <>
                                                                        <div className="dropdown-item">
                                                                            <button onClick={() => this.emitirVenta(d.original)}
                                                                                className="btn btn-dark btn-sm w-100"
                                                                                disabled={this.state.isLoading}
                                                                                title={"Emitir venta"}
                                                                            >
                                                                                <i className="fas fa-share-square"></i> Emitir
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            }
                                        ]
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.showEmailModal} onClose={this.onCloseModal} title={"Enviar correo"}
                       handleKeyUp={this.onKeyUpModal}>
                    <div style={{width: "300px", height: "100px"}}>
                        <form method="post">
                            <div className="form-group">
                                <input type="email" className="form-control mr-2"
                                       onChange={e => this.setState({email: e.target.value})}
                                       value={this.state.email} required="required"/>
                                <button disabled={this.state.isLoading} onClick={this.sendEmail}
                                        className="btn btn-primary mt-2 mr-2 mt-2">
                                    Enviar
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal>

            </section>
        )
    }
}
